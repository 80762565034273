<template>
  <div class="about-components">
    <div class="wrap p-t-50">
      <nav>
        <ul>
          <li v-for="(item, index) in navArr" :key="index">
            <router-link :to="{ name: item.name }">
              {{ item.label }}
            </router-link>
          </li>
        </ul>
      </nav>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
  data() {
    return {
      navArr: [
        // {
        //     name:'about',
        //     label:'官方简介'
        // },
        // {
        //     name:'developB',
        //     label:'发展历程'
        // },

        {
          name: 'joinUs',
          label: '加入我们'
        }
        // {
        //     name:'news',
        //     label:'新闻资讯'
        // },
      ]
    }
  },
  created: function() {},
  methods: {}
}
</script>

<style scoped lang="less">
nav {
  ul {
    background-color: white;
    display: flex;
    li {
      display: inline-block;
      flex: 1;
      text-align: center;
      font-size: 18px;
      margin: 30px 0;
      border-right: 1px solid #d9d9d9;
      &:last-child {
        border-right: none;
      }
      a {
        color: #999;
        &:hover,
        &.router-link-exact-active {
          color: #0084ca;
        }
      }
    }
  }
}
</style>
